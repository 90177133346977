import debounce from "lodash/debounce";

// Delaying ZenDesk chatbox script for lighthouse performance improvement (function is called in expandable-nav.js)
export const loadZenDeskScript = (d) => {
    let script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'ze-snippet';
    d.getElementsByTagName('head')[0].appendChild(script);
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=13a6ea0a-8305-42e7-a62b-b62a43cbe946';
    script.onload = triggerEvent
}

//trigger an event once the ze-snippet has loaded
function triggerEvent(e) {
    const zeEvent = new CustomEvent("lazyScriptLoaded", {
        detail: {
            scriptName: "ze-snippet"
        },
        bubbles: false,
        cancelable: true,
        composed: false,
    });
    document.dispatchEvent(zeEvent);
}

const loadWisepopsScripts = (w, i, s, e) => { window[w] = window[w] || function () { (window[w].q = window[w].q || []).push(arguments) }; window[w].l = Date.now(); s = document.createElement('script'); e = document.getElementsByTagName('script')[0]; s.defer = 1; s.src = i; e.parentNode.insertBefore(s, e) };

//Scroll listener to only load and show popup below the fold
const popupScrollListener = debounce(() => {
    const heightOffset = window.innerHeight / 4;
    const ScrolledTop = window.pageYOffset || document.documentElement.scrollTop
    if (ScrolledTop > heightOffset) {

        loadWisepopsScripts('wisepops', 'https://wisepops.net/loader.js?v=2&h=UeUeSMuJ8u');

        return window.removeEventListener("scroll", popupScrollListener);
    }
}, 100);

window.addEventListener("scroll", popupScrollListener);

popupScrollListener(); //trigger it once anyway, incase the page is already scrolled down.
