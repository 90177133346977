
export function isInsideElement(e, tagName) {
  const isTagDescendant = elem => {
    if (elem.tagName.toLowerCase() === tagName) return true;
    if (!elem.parentElement) return false;
    return isTagDescendant(elem.parentElement);
  };
  return isTagDescendant(e.target);
}

export function isInsideNode(e, node) {
  const isNodeDescendent = elem => {
    if (elem.isSameNode(node)) return true;
    if (!elem.parentNode) return false;
    return isNodeDescendent(elem.parentNode);
  };
  return isNodeDescendent(e.target);
}
