export function renderShareMenu() {
    //generate the mailto link.
    //Get the title -- most probably the H1 of the page.
    const pageTitle = document.querySelectorAll("h1")[0]?.textContent ?? "";
    const URLencodedpageTitle = encodeURI(pageTitle);
    //Get the current location
    const currentURL = document.location.href;
    const mailToLink = `mailto:?subject=Learn%20more%20about%20${URLencodedpageTitle}&body=${currentURL}`;

    //LinkedInLink
    const linkedInLink = `https://www.linkedin.com/cws/share?url=${currentURL}`;
    //TwitterLink
    const twitterLink = `https://twitter.com/intent/tweet?text=${URLencodedpageTitle}&url=${currentURL}`;
    const facebookLink = `https://www.facebook.com/sharer.php?u=${currentURL}`;

    return `<div class="text-hero__share-widget no-print d-none">
    <div
      class="share-widget widget dropdown ignore-placement-fix"
      id="share-widget-popover-content"
    >
      <div
        role="menu"
        class="share-widget__menu widget-menu dropdown-menu focusable-menu widget-menu-left"
        aria-labelledby="share-widget-button"
      >
        <div class="share-widget__menu-inner">
                    <ul class="mb-0 list-unstyled">
                    <li class="mb-0 text-util-md share-menu-header">
              <div
                class="share-widget__action share-Link">
                <span class="share-widget__text">Share Page</span>
                <span class="sr-only">
                  This link opens in a new window.
                </span>
              </div>
            </li>
            <li class="mb-0 text-util-md">
              <a
                id="linkedin"
                role="menuitem"
                class="share-widget__action share-Link"
                href='${linkedInLink}'
                rel="noreferrer noopener"
                target="_blank"
              >
                ${getSVG("linkedin")}
                <span class="share-widget__text">LinkedIn</span>
                <span class="sr-only">
                  This link opens in a new window.
                </span>
              </a>
            </li>
            <li class="mb-0 text-util-md">
              <a
                id="twitter"
                role="menuitem"
                data-text="${pageTitle}"
                data-author=""
                class="share-widget__action share-Link"
                href='${twitterLink}'
                rel="noreferrer noopener"
                target="_blank"
              >
                ${getSVG("twitter")}
                <span class="share-widget__text">Twitter</span>
                <span class="sr-only">
                  This link opens in a new window.
                </span>
              </a>
            </li>
            <li class="mb-0 text-util-md">
              <a
                class="share-widget__action share-Link"
                href='${facebookLink}'
                id="facebook"
                role="menuitem"
                rel="noreferrer noopener"
                target="_blank"
              >
                ${getSVG("facebook")}
                <span class="share-widget__text">Facebook</span>
                <span class="sr-only">
                  This link opens in a new window.
                </span>
              </a>
            </li>
            <li class="mb-0 text-util-md">
              <a
                class="share-widget__action share-Link"
                href='${mailToLink}'
                id="email-link"
                role="menuitem"
                target="_blank"
                rel="nofollow"
              >
                ${getSVG("email")}
                <span class="share-widget__text">Email</span>
                <span class="sr-only">
                  This link opens in a new window.
                </span>
              </a>
            </li>
          </ul>
        </div> 
      </div>
      <span class="share-widget__overlay"></span>
    </div>
  </div>`;

}

/**
 * Get's the svg based on the SVG name.
 * @param {string} name
 * @returns
 */
export function getSVG(name) {
    let markup = "";
    switch (name) {
        case "linkedin":
            markup = `<svg aria-labelledby="svg-inline--fa-title-linkedin-sharemenu" data-prefix="fab" data-icon="linkedin-in" class="svg-inline--fa fa-linkedin-in fa-w-16 fa-lg icon" style="width: 25px; height:25px; margin-right: 0.75rem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title id="svg-inline--fa-title-linkedin-sharemenu">LinkedIn</title>
            <path d="M5.5,1h9A4.49,4.49,0,0,1,19,5.5v9A4.49,4.49,0,0,1,14.5,19h-9A4.49,4.49,0,0,1,1,14.5v-9A4.49,4.49,0,0,1,5.5,1Zm.62,8.1v5.4m3.6,0V11.35A2.25,2.25,0,0,1,12,9.1h0a2.25,2.25,0,0,1,2.25,2.25V14.5M6.12,5.84a.23.23,0,1,0,.22.22.22.22,0,0,0-.22-.22" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>`;
            break;
        case "facebook":
            markup = `<svg aria-labelledby="svg-inline--fa-title-facebook-sharemenu" data-prefix="fab" data-icon="-in" class="svg-inline--fa fa--in fa-w-16 fa-lg icon" style="width: 25px; height:25px; margin-right: 0.75rem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title id="svg-inline--fa-title-facebook-sharemenu">Facebook</title>
            <path d="M5.5,1h9A4.49,4.49,0,0,1,19,5.5v9A4.49,4.49,0,0,1,14.5,19h-9A4.49,4.49,0,0,1,1,14.5v-9A4.49,4.49,0,0,1,5.5,1Zm3.6,9.9h5.4m0-4.5h-.95A2.65,2.65,0,0,0,10.9,9.05V19" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>`;
            break;
        case "twitter":
            markup = `<svg aria-labelledby="svg-inline--fa-title-twitter-sharemenu" data-prefix="fab" data-icon="twitter-in" class="svg-inline--fa fa-twitter-in fa-w-16 fa-lg icon" style="width: 25px; height:25px; margin-right: 0.75rem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title id="svg-inline--fa-title-twitter-sharemenu">Twitter</title>
            <path d="M5.5,1h9A4.49,4.49,0,0,1,19,5.5v9A4.49,4.49,0,0,1,14.5,19h-9A4.49,4.49,0,0,1,1,14.5v-9A4.5,4.5,0,0,1,5.5,1Z" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path d="M15.05,8.55c0,4.2-2.4,7.2-6.6,7.2a4.68,4.68,0,0,1-4.2-2.4" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path d="M4.25,13.35l1.8-.6a5.55,5.55,0,0,1-.6-7.2,6.09,6.09,0,0,0,3.6,3,2.92,2.92,0,0,1,3-3,2.62,2.62,0,0,1,2.4,1.2h1.8l-1.2,1.8" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>`;
            break;
        case "email":
            markup = `<svg aria-labelledby="svg-inline--fa-title-email-sharemenu" data-prefix="fab" data-icon="email-in" class="svg-inline--fa fa-email-in fa-w-16 fa-lg icon" style="width: 25px; height:25px; margin-right: 0.75rem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title id="svg-inline--fa-title-email-sharemenu">Email</title>
            <path d="M17,17H3a2,2,0,0,1-2-2V5A2,2,0,0,1,3,3H17a2,2,0,0,1,2,2V15a2,2,0,0,1-2,2ZM16,6l-6,4L4,6" fill="none" stroke="#17468f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </svg>`;
    }

    return markup;
}
