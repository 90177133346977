export function setCssVariables() {
    const header = document.querySelector('header.site-header');
    if (!header) return;

    const headerHeight = header.offsetHeight;

    document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
    document.addEventListener("headerHeightEvent", function(e) {
        const headerHeight = e.detail && e.detail.headerHeight !== undefined
            ? e.detail.headerHeight
            : 0;
        const alertsHeight = e.detail && e.detail.cumilativeAlertBarsHeight !== undefined
            ? e.detail.cumilativeAlertBarsHeight
            : 0;
        document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
        document.documentElement.style.setProperty('--alert-bars-height', alertsHeight + 'px');
    });
}