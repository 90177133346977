function homepageScripts() {
    if (!document.body.classList.contains("home-page")) return;

//for all the compound-hero__bg images
    $(".compound-hero__bg img").each(function () {
        $item = $(".compound-hero__bg img");
        $imgUrl = $item.data("src");
        $item.parent().css("background-image", 'url("' + $imgUrl + '")').addClass("loaded");
    })

    //For mp4 banner instead of static banner'
    const banner = document.getElementById('video-banner');
    const bannerHome = document.getElementById('video-banner-home');
    if (banner) {
        function setVideoBanner(data) {
            let vData;
            vData = banner.getAttribute(data);
            vData !== undefined && banner.setAttribute("src", vData);
            bannerHome.load();
}
        ($(window).width() <= 480) ? setVideoBanner("data-mobilesource") : setVideoBanner("data-desktopsource");
    }
}

window.addEventListener("DOMContentLoaded", homepageScripts);
