
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const FILE_REGEX = /(doc|docx|pdf|png|ppt|mp3|txt|xls|xlsx|xml|xsd|zip)$/ig;

export const HTTPS_REGEX = /^https?:\/\//;

export const IE_BROWSER_REGEX = /Trident\/7\./;

// eslint-disable-next-line max-len
export const MOBILE_BROWSER_REGEX = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/;

/*
  Regex taken from "Regular Expressions Cookbook, 2nd Edition by Steven Levithan, Jan Goyvaerts"
  - Only matches 10 digit numbers
  - Optional '+1' at beginning
*/
export const PHONE_NUMBER_REGEX = /(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/g;

