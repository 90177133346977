/****
 * CUSTOM DROPDOWNS FOR FORMS***
 */
window.customDropdown = {
    init: function (form, dropClass) {
        $(form).find(dropClass).each(function () {
            //customDropdown.fixDefaultValue($(this)); //DefaultValueBugFix
            customDropdown.addDropdown($(this)); // Add dropdowns
            customDropdown.dropdownEventHandler($(this)); //Dropdown event handler
        });
    },
    dropdownEventHandler: function (dropWrap) {
        dropWrap.find(".dropdown-item").click(function () {
            dropWrap.find(".dropdown__button span").text($(this).text());
            customDropdown.changeSelector(dropWrap.find("select"), $(this).attr("value"));
            console.log(dropWrap.find(".dropdown__button span"), "text");
            //dropWrap.find(".dropdown__button").attr("data-value", $(this).attr("value"));
        });
    },
    changeSelector: function (dropdown, selectedVal) {
        dropdown.find("option").removeAttr("selected");
        dropdown.find("option").each(function () {
            if ($(this).attr('value') == selectedVal) {
                $(this).attr("selected", "selected");
                $(this).prop("selected", true);
                dropdown.val(selectedVal).change();
                $(dropdown).prop("value", selectedVal).trigger('customDropdownChange');
            }
        });
    },
    addDropdown: function (dropWrap) {
        var _html = '';
        var selectElm = dropWrap.find("select");

        // IE fix
        selectElm.find('[data-f-default-value]').attr("selected", "selected");

        _html += '<div class="dropdown-menu dropdown__menu text-util-sm" role="menu">';

        selectElm.find("option").each(function () {
            _html += '<button class="dropdown-item dropdown__item text-util-sm" role="menuitem" type="button" value="' + $(this).val() + '">' + $(this).text() + '</button>';
        });

        _html += "</div>";

        _html += '<button aria-haspopup="true" aria-expanded="false" data-value="' + selectElm.find("option:selected").val() + '" class="dropdown__button text-util-sm dropdown__button-default" data-toggle="dropdown" id="' + selectElm.attr('id') + '-select" type="button">';
        _html += '<span class="dropdown__label" >' + selectElm.find(":selected").text() + '</span >';
        _html += '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-16 fa-2x dropdown__icon dropdown__icon-default" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">';
        _html += '<path fill="currentColor" d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z"></path>';
        _html += '</svg></button>';

        

        selectElm.hide();
        dropWrap.append(_html);

    },
    fixDefaultValue: function (dropWrap) {
        //Fix epiforms bug where the default element is a null value. change this to a space so it is read and changed to an empty value via Epi JS.
        var $select = $(dropWrap).find("select");

        //get the option where a value is null i.e. the default option
        defaultElem = $select.find("option").toArray().find(x => !x.value)

        //No problem if there is no null option.
        if (!defaultElem) return;

        //Add a value with space, remove the disabled attribute.
        $(defaultElem).val(" ");
        $(defaultElem).removeAttr("disabled");
    }
}

window.addEventListener("DOMContentLoaded", () => {
    //Custom dropdown init
    customDropdown.init(".custom-select-form", ".dropdown");
})