import debounce from "lodash/debounce";

window.addEventListener("DOMContentLoaded", () => {
    let moreOrLessReplays = $(".toggle-info");
    if (moreOrLessReplays) {
        moreOrLessReplays.on("click", function () {
            let exploreMore = 'Explore More Webinars';
            if ($(".replays-section").length > 0) {
                if ($(this).text().indexOf(exploreMore) > -1) {
                    $(this).find(".toggle-text").text('Show Less')
                    $(this).find(".chevron").toggleClass("face-down");
                    $(this).find(".chevron").toggleClass("face-up");
                    $(".mobile-initial-hide").each(function () {
                        $(this).show();
                    });
                } else {
                    $(this).find(".toggle-text").text(exploreMore)
                    $(this).find(".chevron").toggleClass("face-up");
                    $(this).find(".chevron").toggleClass("face-down");
                    $(".mobile-initial-hide").each(function () {
                        $(this).hide();
                    });

                    $('html,body').animate({
                        scrollTop: $(".replays-section").offset().top
                    }, 1000);
                }
            }
        });
    };

    window.addEventListener("resize", debounce(() => {
        if (window.innerWidth > 1024) {
            $(".mobile-initial-hide").each(function () {
                $(this).show();
            });
        }
        else {
            if ($(".replays-section").text().indexOf('Explore More Webinars') > -1) {
                $(".mobile-initial-hide").each(function () {
                    $(this).hide();
                });
            }
            else {
                $(".mobile-initial-hide").each(function () {
                    $(this).show();
                });
            }
        }
    }, 300));
});

