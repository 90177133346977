require("./jquery-ui-autocomplete.min.js");
const { setCssVariables } = require("../../../../../js/globalCssVariables");
const searchInputAutoCompMinLen = 3;
const globalNavBtnSearchSelector = '#global-nav-btn-search';
const globalNavPlaceholderSelector = '.header-search-overlay .input-placeholder';

// AHP functions (taken from AU scripts index.js and global-nav.js)

var isCloseAhpMobile = false;

window.addEventListener('closeAhp', function () {
    isCloseAhpMobile = true;
});


$(document).ready(function () {

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    var processSearch = function () {
        var searchString = $('.search-input').val();

        if (searchString) {
            window.location.href = "/search/?searchtext=" + encodeURIComponent(decodeURIComponent(searchString));
        }
    }

    $(globalNavBtnSearchSelector).on("click", async function (e) {
        if ($('.header-search-overlay .search-input').val().length > 0 && !$(globalNavPlaceholderSelector).hasClass('active')) {
            $(globalNavPlaceholderSelector).addClass('active');
        }
    });

    /* load search autocomplete source on search icon click */
    $(globalNavBtnSearchSelector).one("click", async function (e) {
        $(".search-input").autocomplete(
            {
                source: function (request, response) {
                    $.ajax(
                        {
                            url: "/common/SearchAuPage/SearchByText/?searchText="
                                + encodeURIComponent(request.term),
                            success: function (data) {
                                if (data.hits.length !== 0) {
                                    $('.popular-items-container').hide();
                                } else {
                                    $('.popular-items-container').show();
                                }
                                response($.map(data.hits, function (item) {
                                    return {
                                        label: decodeHtml(item.Title),
                                        description: item.Description,
                                        url: item.Url,
                                        isFundPage: item.IsFundPage,
                                        isGroupTitle: item.IsGroupTitle
                                    };
                                }));
                            }
                        }, 300);
                },
                appendTo: '#autocomplete-results',
                minLength: searchInputAutoCompMinLen,
                delay: 300,
                select: (e, t) => {
                    e.preventDefault();
                    location.href = t.item.url;
                },
                close: () => {
                    $('.popular-items-container').show();
                },
            });

        $(".search-input").autocomplete("instance")._renderItem = function (ul, item) {
            const searchTerm = this.term;
            const regex = new RegExp(`(${searchTerm})`, 'gi');
            const highlightedLabel = item.label.replace(regex, "<span class='highlight'>$1</span>");

            return $(`${item.url ? `<a href="${item.url}">` : ''}<li class='${item.isGroupTitle && item.isGroupTitle === true ? 'group-title': ''} ${item.isFundPage && item.isFundPage === true ? 'fund-page': ''}'>${highlightedLabel} ${item.description ? '<br />${item.description}' : ''}</li>${item.url ? '</a>' : ''}`)
                .on('click', function(e) {
                    if (item.isGroupTitle === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }
                })
                .appendTo(ul);
        };
    });

    $('.search-input').keypress(function (e) {
        if (e.which == 13) {
            e.preventDefault();
            processSearch();
        }
    });

    $('.input-container .fa-search').click(function () {
        processSearch();
    });

    //Click outside: anywhere on the screen but the searchbar
    $("#global-banner-wrapper").bind("clickoutside", function (event) {
        var windowsize = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowsize > 798) {
            var target = $(this),
                targetSlide = $($(this).attr('href'));

            target.toggleClass('active');
            $('.global-nav-search-trigger').not($(this)).removeClass('active');
            targetSlide.toggleClass('active');
            if (targetSlide.is('.active')) {
                targetSlide.focus();
            }
            $('.global-nav-search-toggle').not(targetSlide).removeClass('active');
        }
        return false;
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        var countrycodes = "1";
        var delimiters = "-|\\.|—|–|&nbsp;";
        var phonedef = "\\+?(?:(?:(?:" + countrycodes + ")(?:\\s|" + delimiters + ")?)?\\(?[2-9]\\d{2}\\)?(?:\\s|" + delimiters + ")?[2-9]\\d{2}(?:" + delimiters + ")?[0-9a-z]{4})";
        var spechars = new RegExp("([- \(\)\.:]|\\s|" + delimiters + ")", "gi"); //Special characters to be removed from the link
        var phonereg = new RegExp("((^|[^0-9])(href=[\"']tel:)?((?:" + phonedef + ")[\"'][^>]*?>)?(" + phonedef + ")($|[^0-9]))", "gi");

        var replacePhoneNumbers = function (oldhtml) {
            if (oldhtml !== undefined) {
                var newhtml = oldhtml.replace(/href=['"]callto:/gi, 'href="tel:');
                newhtml = newhtml.replace(phonereg, function ($0, $1, $2, $3, $4, $5, $6) {
                    if ($3) return $1;
                    else if ($4) return $2 + $4 + $5 + $6;
                    else return $2 + "<a href='tel:" + $5.replace(spechars, "") + "'>" + $5 + "</a>" + $6;
                });
                return newhtml;
            }
        };

        $(".ve-phone").each(function () {
            $(this).html(replacePhoneNumbers($(this).html()));
        });
    }

    // #region AHP

    var htmlConstants = {
        spanCaretHtml: ' <span class="fa fa-angle-down"></span>'
    };

    var classConstants = {
        openClass: 'show', //changed from open to show to account for different Bootstrap version
        arrowUp: 'fa fa-angle-up',
        arrowDown: 'fa fa-angle-down'
    };

    var tagConstants = {
        buttonTag: 'button'
    };

    var sc = {
        hiddenSelectedCountry: 'input#hdnSelectedCountry',
        hiddenSelectedInvestorType: 'input#hdnSelectedAudience',
        hiddenCountryUrl: 'input#hdnCountryUrl',
        dataUrlSelector: 'input#hdnDataUrl',
        countrySelectClick: 'div.btn-group.countries ul.dropdown-menu li a',
        countryButton: 'div.btn-group.countries button',
        investorTypeButton: 'div.btn-group.investortype button',
        goButton: 'div.btn-group.go a',
        investorTypeSelectClick: 'div.btn-group.investortype ul.dropdown-menu li .investortype__option',
        investorTypeDropdown: 'ul.dropdown-menu.investortype',
        websiteLangDropdown: 'div#site-lang-dropdown',
        websiteLangDropdownList: 'div#site-lang-dropdown ul',
        ahpLangDropdown: 'div#ahp-lang-dropdown',
        ahpLangDropdownList: 'div#ahp-lang-dropdown ul',
        ahpLangText: 'div#ahp-lang-dropdown > a',
        ahpLangDropdownClick: 'div#ahp-lang-dropdown ul li a',
        ahpSelector: '.global-nav-slidedown-trigger',
        hiddenLanguage: 'input#hdnLanguage',
        selectCountryText: '#select-country-text',
        selectInvestorTypeText: '#select-investor-text',
        disclaimerHeader: '#disclaimer-header',
        hiddenCurrentLanguage: '#hdnCurrentLanguage',
        disclaimerSection: '#disclaimer-section',
        ahpSearchButton: '.global-nav-search-trigger',
        currentCountryName: '#hdnCurrentCountryName'
    };

    var actionMethods = {
        getInvestorAndLanguages: 'getinvestorandlanguages',
        getTranslations: 'gettranslations',
        getDisclaimer: 'getdisclaimer'
    };

    pushGtmEvent = function (event, action) {
        if (typeof dataLayer !== "undefined" && typeof dataLayer.push !== "undefined") {
            dataLayer.push({
                'event': event,
                'eventAction': action
            });
        }
    };

    pushGtmMicrositeClickEvent = function ($obj) {
        if (($obj instanceof jQuery) === false) {
            $obj = $($obj);
        }

        var microsite = $obj.data('microsite');

        if (microsite) {
            pushGtmEvent('ahp-selector-microsite-click-event', microsite);
        }
    };

    acceptClickEvent = function (url, target) {
        var decodedUrl = decodeURIComponent(url);
        if (target === '') {
            target = '_self';
        }
        window.location.target = target;
        var parentUrl = window.location.toString();
        if (parentUrl.match('#')) {
            decodedUrl = decodedUrl + '#' + parentUrl.split('#')[1];
        }
        window.location.href = decodeURIComponent(decodedUrl);
        return false;
    };

    declineClickEvent = function (url, target) {
        if (url === "/") {
            window.location.reload();
        }
        else {
            var decodedUrl = decodeURIComponent(url);
            if (target === '') {
                target = '_self';
            }
            window.location.target = target;
            var parentUrl = window.location.toString();
            if (parentUrl.match('#')) {
                decodedUrl = decodedUrl + '#' + parentUrl.split('#')[1];
            }
            window.location.href = decodeURIComponent(decodedUrl);
        }
        return false;
    };

    disableElement = function (id) {
        $(id).addClass('disabled');
    };

    enableElement = function (id) {
        $(id).removeClass('disabled');
    };

    clickInit = function (obj, e) {
        var $div = obj.parent().parent().parent();
        var $btn = $div.find(tagConstants.buttonTag);
        $btn.html(obj.text() + htmlConstants.spanCaretHtml);
        $div.removeClass(classConstants.openClass);
        e.preventDefault();
    };

    getData = function (action, param1, param2, param3, param4, param5, param6, param7) {
        return $.ajax({
            type: "GET",
            url: $(sc.dataUrlSelector).val(),
            data: {
                "op": action, "param1": param1, "param2": param2, "param3": param3, "param4": param4, "param5": param5, "param6": param6, "param7": param7
            },
            cache: false,
            async: false
        });
    };

    changeAhpLanguage = function (lang) {
        var target = lang;
        $(sc.ahpLangText).html(target.html() + '<i class="fa fa-angle-up"></i>');
        var oldLanguageIso = $(sc.hiddenLanguage).val();
        $(sc.hiddenLanguage).val(target.data('id'));

        var audienceType = $(sc.hiddenSelectedInvestorType).val();

        var countryIso = $(sc.hiddenSelectedCountry).val();
        var countryName = '';
        var countryUrl = $(sc.hiddenCountryUrl).val();
        if ($(sc.disclaimerHeader).length > 1) {
            audienceType = $(sc.disclaimerHeader).data('audience');
            countryIso = $(sc.disclaimerHeader).data('countryiso');
            countryName = $(sc.disclaimerHeader).data('countryname');
            countryUrl = $(sc.disclaimerHeader).data('accepturl');
        }
        else {
            audienceType = $(sc.hiddenSelectedInvestorType).val();
            countryIso = $(sc.hiddenSelectedCountry).val();
            countryName = $(sc.currentCountryName).val();
            countryUrl = $(sc.hiddenCountryUrl).val();
        }
        var defaultDeepLinkSession = '';
        if ($('body').hasClass('disclaimer-deeplink')) {
            defaultDeepLinkSession = 'true';
        }
        var translations = getData(actionMethods.getTranslations, target.data('id'), audienceType, countryIso, countryName, countryUrl, oldLanguageIso, defaultDeepLinkSession);
        translations.done(function (translation) {
            $.each(translation.List, function (index, value) {
                $('.content-item-t').each(function () {
                    var regex = new RegExp(value.Text, 'g');
                    var translatedHtml = $(this).html().replace(regex, value.TranslatedText);
                    $(this).html(translatedHtml);
                });
            });
            var contentHtml = '';
            if (translation.Text !== null && translation.Text !== '') {
                contentHtml = '<h1 id="disclaimer-header">' + translation.Header + '</h1>' +
                    '<div class="disclaimer-text">' + translation.Text + '</div>' +
                    '<div class="disclaimer-button">' +
                    '<a id="btnAgree" class="btn btn-primary agree no-animation" data-ve-gtm="ahp-disclaimer-agree" onclick=acceptClickEvent("' + translation.AcceptUrl + '")>' + translation.AgreeText + '</a>' +
                    '<a class="btn btn-primary decline no-animation" data-ve-gtm="ahp-disclaimer-decline" onclick=declineClickEvent("' + translation.DeclineUrl + '")>' + translation.DeclineText + '</a>' +
                    '</div>';
            }
            else {
                contentHtml = '<div class="disclaimer-button">' +
                    '<a id="btnAgree" class="btn btn-primary agree no-animation" data-ve-gtm="ahp-disclaimer-agree" onclick=acceptClickEvent("' + translation.AcceptUrl + '")>Go</a>' +
                    '</div>';
            }
            $(sc.disclaimerSection).html(contentHtml);
        });
    };

    investorTypeSelection = function (thisaction, event, action, investorType, selectedCountry, returnUrl, currentUrl) {
        clickInit(thisaction, event);
        $(sc.hiddenSelectedInvestorType).val(thisaction.data('id'));
        console.log($(sc.currentCountryName).val());
        var disclaimerJson = getData(action, $(sc.hiddenSelectedInvestorType).val(), selectedCountry, returnUrl, currentUrl, $(sc.hiddenLanguage).val(), '', $(sc.currentCountryName).val());
        disclaimerJson.done(function (disclaimer) {
            var contentHtml = '';
            if (disclaimer.Text !== null && disclaimer.Text !== '') {
                contentHtml = '<h1 id="disclaimer-header">' + disclaimer.Header + '</h1>' +
                    '<div class="disclaimer-text">' + disclaimer.Text + '</div>';
            }
            contentHtml = contentHtml + '<div class="disclaimer-button">' +
                '<a id="btnAgree" class="btn btn-primary agree no-animation" data-ve-gtm="ahp-disclaimer-agree" onclick=acceptClickEvent("' + disclaimer.AcceptUrl + '")>' + disclaimer.AgreeText + '</a>' +
                '<a class="btn btn-primary decline no-animation" data-ve-gtm="ahp-disclaimer-decline" onclick=declineClickEvent("' + disclaimer.DeclineUrl + '")>' + disclaimer.DeclineText + '</a>' +
                '</div>';
            $(sc.disclaimerSection).html(contentHtml);
        }).always(function () {
            $(".global-nav-slidedown, .global-nav-slidedown-internal").removeClass("overflow-visible"); //make sure the overflow class is removed when the dropdown is closed.
            $('.investortype button').attr('aria-expanded', 'false');
            $('.investortype ul').removeClass('show');
        });

        return false;
    };

    $(sc.countrySelectClick)
        .click(function (e) {
            clickInit($(this), e);
            var blockId = $(this).data('blockId');
            var currentUrl = encodeURIComponent(window.location.pathname + window.location.search);
            $(sc.hiddenSelectedCountry).val($(this).data('id'));
            $(sc.hiddenLanguage).val('');
            $(sc.currentCountryName).val($(this).data('name'));
            $(sc.disclaimerSection).html('');

            var $div = $(this).parent().parent().parent();
            var $btn = $div.find(tagConstants.buttonTag);
            $btn.attr('class', 'btn btn-default dropdown-toggle global-nav-flag country-' + $(this).text().toLowerCase().replace(/\s/g, '-') + ' content-item-t');

            var jsonData = getData(actionMethods.getInvestorAndLanguages, blockId, 'en', $(this).data('id'), currentUrl);
            jsonData.done(function (json) {
                if (json.InvestorType !== null) {
                    var investorHtml = '';
                    $.each(json.InvestorType, function (key, value) {
                        investorHtml += '<li><div data-id="' + key + '" class="content-item-t investortype__option">' + value + '</div></li>';
                    });
                    $(sc.investorTypeDropdown).html(investorHtml);
                }
                $(sc.investorTypeButton).html('Select Investor Type <span class="fa fa-angle-down"></span>');
                enableElement(sc.investorTypeButton);
                disableElement(sc.goButton);
                $(sc.investorTypeSelectClick)
                    .click(function (e) {
                        investorTypeSelection($(this), e, actionMethods.getDisclaimer, $(sc.hiddenSelectedInvestorType).val(), $(sc.hiddenSelectedCountry).val(), '', currentUrl);
                    });

                if (json.Languages !== null) {
                    var languageHtml = '';

                    var length = 0;
                    for (var key in json.Languages) {
                        length++;
                    }

                    if (length > 1) {
                        $.each(json.Languages, function (key, value) {
                            languageHtml += '<li><a data-id="' + key + '">' + value + '</a></li>';
                        });
                        $(sc.ahpLangDropdownList).html(languageHtml);
                        $(sc.ahpLangText).html($(sc.hiddenCurrentLanguage).val() + ' <i class="fa fa-angle-down"></i>');
                    }
                    else {
                        $(sc.ahpLangText).html($(sc.hiddenCurrentLanguage).val());
                    }
                }

                $(sc.ahpLangDropdownClick)
                    .click(function (e) {
                        changeAhpLanguage($(this));
                    });
            }).always(function () {
                $(".global-nav-slidedown, .global-nav-slidedown-internal").removeClass("overflow-visible"); //make sure the overflow class is removed when the dropdown is closed.
                $('.btn-group.countries button').attr('aria-expanded', 'false');
                $('.btn-group.countries ul').removeClass('show');
            });

            return false;
        });

    $(sc.investorTypeSelectClick)
        .click(function (e) {
            investorTypeSelection($(this), e, actionMethods.getDisclaimer, $(sc.hiddenSelectedInvestorType).val(), $(sc.hiddenSelectedCountry).val(), encodeURIComponent(window.location.pathname + window.location.search), '');
        });

    $(sc.goButton)
        .click(function (e) {
            var className = $(this).attr('class');
            if (className.indexOf("disabled") >= 0) {
                return false;
            }
            var url = decodeURIComponent($(sc.hiddenCountryUrl).val());
            window.location.href = decodeURIComponent(url);
        });

    $(sc.ahpLangDropdownClick)
        .click(function (e) {
            changeAhpLanguage($(this));
        });

    $(sc.ahpLangDropdown)
        .click(function (e) {
            var targetAnchor = $(this).find('a#dropdownMenuButton');
            if ($(this).is('.show')) {
                targetAnchor.find('i').removeClass(classConstants.arrowUp);
                targetAnchor.find('i').addClass(classConstants.arrowDown);
            }
            else {
                targetAnchor.find('i').removeClass(classConstants.arrowDown);
                targetAnchor.find('i').addClass(classConstants.arrowUp);
            }
            $(this).find('ul li').each(function (i) {
                $(this).show();
            });
            var elemToHide = $(this).find('ul').find('li').find('a:contains("' + $.trim(targetAnchor.text()) + '")');
            elemToHide.parent().hide();
        });

    $(sc.ahpSelector).click(function () {

        var target = $(this),
            targetSlide = $($(this).attr('href'));

        target.toggleClass('active');

        targetSlide.toggleClass('active');
        if (targetSlide.is('.active')) {
            targetSlide.focus();
            $(sc.ahpLangDropdown).show();
            $(sc.websiteLangDropdown).hide();
            $('#global-nav-btn-search').css({ 'visibility':'hidden' });
        }
        else {
            $(sc.ahpLangDropdown).hide();
            $(sc.websiteLangDropdown).show();
            $('#global-nav-btn-search').css({ 'visibility': 'visible' });
        }

        $('.global-nav-toggle').not(targetSlide).removeClass('active');

        var id = $(this).attr('id');
        if (id !== undefined && id === 'global-nav-content') {
            $(window).scrollTop(0);
            if (!targetSlide.is('.active')) {
                targetSlide.addClass('active');
            }
        }

        $(document).click();

        return false;
    });

    $(sc.ahpSearchButton).click(function () {
        $('.header-search-overlay').addClass('active');
        $('body, .wrapper, footer').addClass('blur');
    });

    $('.close-btn-container').click(function () {
        $('.header-search-overlay').removeClass('active');
        $('body, .wrapper, footer').removeClass('blur');
    });

    $('.header-search-overlay .search-input').keyup(function () {
        if ($(this).val().length > 0) {
            $('.header-search-overlay .input-placeholder').addClass('active');
        } else {
            $('.header-search-overlay .input-placeholder').removeClass('active');
        }
    });

    if ($(sc.ahpSelector).is('.active')) {
        $(sc.ahpLangDropdown).show();
        $(sc.websiteLangDropdown).hide();
    }

    $(sc.countryButton)
        .click(function () {
            if ($(this).parent().is('.show')) {
                $(this).find('span').attr('class', 'fa fa-angle-down');
            }
            else {
                $(this).find('span').attr('class', 'fa fa-angle-up');
            }
        });

    $(sc.investorTypeButton)
        .click(function () {
            if ($(this).parent().is('.show')) {
                $(this).find('span').attr('class', 'fa fa-angle-down');
            }
            else {
                $(this).find('span').attr('class', 'fa fa-angle-up');
            }
        });

    // #endregion


    // #region AHP Mobile

    $('#ahpMobileClick').click(function () {
        $('.menu-mobile > ul').toggleClass('show');
        $('.close-nav-b').toggleClass('close-nav');
        $('.push-right').toggleClass('close-body');
        $('.menu-mobile').toggleClass('js-ready');
        $('.mobile-push').toggleClass('push-right');

        $('#mobile').hide();
        $('.mainWrapper').hide();
        $('.wrapper').css({ 'position': 'fixed' });
        $('#ahpMobile').show();
        setTimeout(function () { $('body').removeClass('no-overflow') }, 1000);
        $('body').css({ 'display': 'none' });
        setTimeout(function () { $('body').css({ 'display': 'block' }) }, 100);
    });

    $('#closeAhpModal').click(function () {
        $('.menu-mobile > ul').toggleClass('show');
        $('.close-nav-b').toggleClass('close-nav');
        $('.push-right').toggleClass('close-body');
        $('.menu-mobile').toggleClass('js-ready');
        $('.mobile-push').toggleClass('push-right');

        $('#mobile').show();
        $('.mainWrapper').show();
        $('.wrapper').css({ 'position': 'relative' });
        $('#ahpMobile').hide();

        var closeAhpEvent = document.createEvent('Event');

        closeAhpEvent.initEvent('closeAhp', true, true);

        window.dispatchEvent(closeAhpEvent);

        $('.global-nav-toggle').removeClass('active');
    });

    $(window).resize(function () {
        if ($(window).width() < 992) {
            $('.header-search-overlay').removeClass('active');
            $('body, .wrapper, footer').removeClass('blur');
        }
    });
    // #endregion

    // #AddOverflowClass - Add a class when country and investor dropdown are open to enable them to overflow out of the ahp block.
    $(".global-nav-slidedown .btn-group").on('show.bs.dropdown hidden.bs.dropdown', function (e) { /* to force overflow when dropdown is opened, else dropdown will not be visible. */
        if (window.innerWidth > 992 || window.innerHeight > 640) $(".global-nav-slidedown, .global-nav-slidedown-internal").toggleClass("overflow-visible");
    })
    //Close dropdowns on orientation change to reset the overflow class as it is based on screen dimensions
    $(window).on("orientationchange", function (e) {
        $(".global-nav-slidedown .btn-group.show").find("button").click();
    })
    //Ensure that overflow class is removed whenever the dropdown is closed
    $(".global-nav-slidedown .btn-group").on('hidden.bs.dropdown', function (e) {
        $(".global-nav-slidedown, .global-nav-slidedown-internal").removeClass("overflow-visible");
    })
    //

    //Brought in from au index.js
    $('a.global-nav-slidedown-trigger').click(function () {
        if ($(this).hasClass('active')) {
            $('.page-overlay').addClass('active');
            $('body').addClass('no-overflow');
        } else {
            if (isCloseAhpMobile) {     // if #closeAhpModal is clicked, it means AHP is closed on mobile, but we want the overlay to display so the 'actve' class remains
                isCloseAhpMobile = false;
            } else if ($('.page-overlay').hasClass('active')) {
                $('.page-overlay').removeClass('active');
                $('body').removeClass('no-overflow');
            }
        }
    });
    $('#closeAhpModal').click(function () {
        setTimeout(() => {
            if (isCloseAhpMobile) return;       // if closing AHP from mobile, we do not want to remove the page overlay

            $('.page-overlay').removeClass('active search-active');
            $('body').removeClass('no-overflow');
        });
    });

    setCssVariables();
});

const initFundMenuAssetClass = options => {
    try {
        options = options || {};

        if (options.assetClasses) {
            let assetClasses = options.assetClasses.split('|');

            assetClasses.forEach(assetClass => {
                assetClass = assetClass.toLowerCase().replaceAll(' ', '.');

                $(`.title-part.${assetClass}`).hover(() => {
                    $('.title-part, .fund-section').removeClass('selected');
                    $(`.title-part.${assetClass}, .fund-section.${assetClass}`).addClass('selected');
                });
            });
        }
    } catch (e) {
        return null;
    }
}

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        let assetClasses = $('#fund-menu-asset-classes').val();
        initFundMenuAssetClass({ assetClasses: assetClasses });

        let thisYear = (new Date()).getFullYear();
        $('.hp-copyright').html(`&copy; ${thisYear} VanEck Australia Pty Ltd.`);

        $('#main-slick-slider, #secondary-slider').css({ 'opacity': '1' });
    }, 1000);
});