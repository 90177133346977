function searchScripts() {

    $('.search-results__input').keydown(function (e) {
        if (e.keyCode == 13) {
            $('.search-results__submit').click();
            return false;
        }
    });

    $('.search-results__submit').click(function () {
        var searchString = $('.search-results__input').val();
        if (searchString) {
            window.location.href = window["sharedData"].SearchFormatString.replace("{0}", encodeURIComponent(searchString));
        }
        return false;
    });

    $('#main-search-box').keydown(function (e) {
        if (e.keyCode == 13) {
            var searchString = $('#main-search-box').val();
            if (searchString) {
                window.location.href = window["sharedData"].SearchFormatString.replace("{0}", encodeURIComponent(searchString));
            }
            return false;
        }
    });

        /* Adds a class to the dynamic modal backdrop so we can style it as needed. */
    var SearchBackdropClass = "bs4-search-backdrop";
    $("#site-header-search").on('shown.bs.modal', function () {
        $('.modal-backdrop').addClass(SearchBackdropClass);
    }).on('hidden.bs.modal', function () { $('.modal-backdrop').addClass(SearchBackdropClass); });

    /* Navigates to the search page once you press enter on the search bar. */
    $(".form-control.search__input").keyup(function (e) {
        if (e.which == 13) {
            window.location.href = window["sharedData"].SearchFormatString.replace("{0}", encodeURIComponent(e.currentTarget.value));
        }
    })


        $('#site-header-search').on('shown.bs.modal', function (e) {
        $('input.search__input').focus();
    })

}

window.addEventListener("DOMContentLoaded", searchScripts);