// Brought in from au main-etf.js

$(document).ready(function () {

    initMainNavigation();

});

function initMainNavigation() {
    $('#drop_tab_green').mouseover(function () {
        if (!$('.drop').hasClass('selected')) {
            $('.drop').addClass('selected');
        }
    });

    $('#drop_tab_green').mouseleave(function () {
        if ($('.drop').hasClass('selected')) {
            $('.drop').removeClass('selected');
        }
    });

    $('.drop').mouseover(function () {
        if (!$('.drop').hasClass('selected')) {
            $('.drop').addClass('selected');
        }
    });

    $('.drop').mouseleave(function () {
        if ($('.drop').hasClass('selected')) {
            $('.drop').removeClass('selected');
        }
    });
}