/**
 * US-SCRIPTS
 * ============
 *
 * This is the main Javascript bundle for the US website.
 */

//1. index.js is from Vshift -- do not modify this path.
require("./sources/index.js");

//2. Overrides from VanEck
require('./override/index.js');

//3. Include the beat the bank JS
//require('./yieldMonitor/main.js');

//4. Include blog page jumplinks
require("./insights/populate-jumplink-nav.js");