
export function preventScrollRestore() {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
}


export function scrollToElement(elem, behavior = 'smooth') {
  elem.scrollIntoView({
    behavior,
    block: 'start',
    inline: 'nearest',
  });
}


export function scrollToElement2(elem, options = {}) {

  const { direction = 'up', behavior = 'smooth', spacing = 30 } = options;

  const elemScrollOffset = elem.getBoundingClientRect().top,
        currentScroll = document.scrollingElement.scrollTop;

  const scrollAmount = elemScrollOffset - spacing;

  if (scrollAmount === 0) return;
  if (direction === 'down' && scrollAmount < 0) return;
  if (direction === 'up' && scrollAmount > 0) return;

  window.scrollTo({
    left: 0,
    top: currentScroll + scrollAmount,
    behavior,
  });
}


export function scrollToSelector(selector, behavior = 'smooth') {
  let elem = null;
  if (selector && selector !== '#') elem = document.querySelector(selector);
  if (!elem) return;
  elem.scrollIntoView({
    behavior,
    block: 'start',
    inline: 'nearest',
  });
}


export function scrollToTop() {
  window.scrollTo(0, 0);
}

