// JavaScript Document
(function ($) {
    $(function () {
        const checkFundMenuOverflow = () => {
            if ($(window).innerHeight() < 850) {
                $('div.menu-item-wrapper div.drop').addClass('ss-overflow');
            } else {
                $('div.menu-item-wrapper div.drop').removeClass('ss-overflow');
            }

            if ($(window).innerHeight() < 670) {
                $('div.menu-item-wrapper div.drop').addClass('smaller-drop-element');
            } else {
                $('div.menu-item-wrapper div.drop').removeClass('smaller-drop-element');
            }
        }

        checkFundMenuOverflow();

        //On scroll event hide swipe button
        $(".table-container-m").on("scroll", function () {
            var cur = $(this).scrollLeft();
            if (cur > 45) {
                $(this).find('.swipe-column').hide();
            }
        });
        $(".table-container-m").trigger("scroll");

        //If table are long then 20 rows, position swipe text to the top of the table 
        $('.table-container-m').each(function () {
            var $this = $(this);
            if ($this.find('tr').length > 20) {
                $this.find('.swipe-column').css("vertical-align", "top");
            }
        });
        //Add text to the first table in the class called swipe-column
        //$(".swipe-column:first").html('Swipe<br />to view <br /> full data<br /><span class="fa fa-arrows-h"></span>');
        //Add text to the all table in the class called swipe-column
        $(".swipe-column").html('Swipe<br />to view <br /> full data<br /><span class="fa fa-arrows-h"></span>');


        //if table has srcoll bars

        $('.table-container-m').each(function () {
            if ($(this)[0].scrollWidth > $(this).width()) {
                $(this).find('.swipe-column').css("display", "table-cell");
            }
            else if ($(window).width() > 768) {
                $(this).find('.swipe-column').css("display", "none");
            }
            else {
                $(this).find('.swipe-column').css("display", "none");
            }
        });

        $(window).resize(function () {
            $('.table-container-m').each(function () {
                if ($(this)[0].scrollWidth > $(this).width()) {
                    $(this).find('.swipe-column').css("display", "table-cell");
                }
                else if ($(window).width() > 768) {
                    $('.table-container-m').find('.swipe-column').css("display", "none");

                }
                else {
                    $(this).find('.swipe-column').css("display", "none");
                }
            });
            if ($(window).width() > 768) {
                /*$('body').removeClass('push-right');*/
                $('body').removeClass('close-body');
                /*$('body div').removeClass('push-right');*/
                $('body div').removeClass('close-nav');
                $('#mobile-nav').removeClass('js-ready');
                $('#mobile-nav ul').removeClass('open');
            }
            if ($(window).width < 992) {
                $('.header-search-overlay').removeClass('active');
                $('body, .wrapper, footer').removeClass('blur');
            }

            checkFundMenuOverflow();
        });


        // Mobile Redesign
        var mobileMethods = {
            onSearchSubmit: function () {
                var searchString = $('#overlay-search-input').val();

                if (searchString) {
                    window.location.href = "/search/?searchtext=" + encodeURIComponent(decodeURIComponent(searchString));
                }
            }
         
        };

        var isMenuOpen = false;
        var isSearchOpen = false;

        window.addEventListener('closeAhp', function () {
            isCloseAhp = true;

            setTimeout(function () { isCloseAhp = false }, 1000);
        });

        $('.search-btn-container').click(function () {
            $(".site-header__mobile-menu-toggle:not(.collapsed)").click()
            $('#mobile-nav, ul.menu-m ul.level-1-m').removeClass('active');
            $('ul.menu-m ul.level-1-m').css({ 'transform': 'translateX(' + (window.innerWidth * -1) + 'px)' });
            //$('.page-overlay').toggleClass('active search-active');

            if (!isSearchOpen) {
                if (isMenuOpen) {
                    isMenuOpen = !isMenuOpen;
                    $('.page-overlay').addClass('search-active');
                } else {
                    $('.page-overlay').addClass('active search-active');
                }
            } else {
                $('.page-overlay').removeClass('active search-active');
            }

            isSearchOpen = !isSearchOpen;
        });

        $('#overlay-search-submit').click(function (e) {
            mobileMethods.onSearchSubmit();
        });

        $('#overlay-search-input').on('keypress', function (e) {
            if (e.which == 13) {
                mobileMethods.onSearchSubmit();
            }
        });

    });
})(jQuery);
