(function () {
    let isScriptLoaded = false;
    let scrollTimeout;

    function handleScroll() {
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }

        scrollTimeout = setTimeout(async () => {
            if (!isScriptLoaded) {
                try {
                    await import('./sticky-premium-form.js');
                    isScriptLoaded = true;

                    window.removeEventListener('scroll', handleScroll);
                } catch (error) {
                    console.error('Script loading error:', error);
                }
            }
        }, 150);
    }
    window.addEventListener('scroll', handleScroll);
})();