//from Static/js/spin.js
import {Spinner} from 'spin.js';

    $(document).ready(function () {
        $("#veSpinnerContainer").append('<div id="loading"><div id="loadingcontent"><p id="loadingspinner"></p></div></div>');
        $("#veLoginSpinnerContainer").append('<div id="loginloading"><div id="loadingcontent"><p id="loadingspinner"></p></div></div>');
        $('#viewport-wide-spinner:not(.dont-hide-default)').hide();


        if (typeof $$epiforms !== 'undefined') {
            console.log("EPiforms is loaded and events bound.")
            epi.EPiServer.Forms.AsyncSubmit = true;

            $$epiforms(document).ready(function myfunction() {
                var spinner;
                // listen to event when form is about submitting
                $$epiforms(".spinner-form").on("formsStartSubmitting",
                    function(data) {
                        spinner = doSpinnerByLoadingId('veSpinnerContainer', null, 'loading');
                    });

                // listen to event when form is successfully submitted
                $$epiforms(".spinner-form").on("formsSubmitted",
                    function(data) {
                        stopSpinnerByLoadingId(spinner, 'veSpinnerContainer', 'loading');
                    });

                // formsSubmittedError
                $$epiforms(".spinner-form").on("formsSubmittedError",
                    function(data) {
                        stopSpinnerByLoadingId(spinner, 'veSpinnerContainer', 'loading');
                    });
            });
        } else {
            console.log("Epiforms not loaded!");
        }
    });

    function doSpinnerByLoadingId(id, eventType, loadingDivId) {
    //If the viewport wide version of the spinner exists, show it.
    $('#viewport-wide-spinner').show();

    if (eventType === "blur" || eventType === "change") {
        $("#" + id).attr('disabled', 'disabled');
    }
    //If want to change the text below the spinning div.
    //if (progressText != undefined) { $("#loadingspinner").html(progressText); }
    $('#' + loadingDivId).fadeIn();
    var opts = {
        lines: 12, // The number of lines to draw
        length: 7, // The length of each line
        width: 4, // The line thickness
        radius: 10, // The radius of the inner circle
        color: '#000', // #rgb or #rrggbb
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false // Whether to use hardware acceleration
    };
    var target = document.getElementById('' + loadingDivId + '');
    var spinner = new Spinner(opts).spin(target);

    return spinner;
}

window.doSpinner = function(id, eventType) {
    doSpinnerByLoadingId(id, eventType, 'loading');
}

function stopSpinnerByLoadingId(spinner, id, loadingDivId) {
    //If the viewport wide version of the spinner exists, hide it.
    $('#viewport-wide-spinner').hide();
    var target = document.getElementById('' + loadingDivId + '');
    $(target).data('spinner', spinner);
    $('[id="' + loadingDivId + '"]').each(function () {
        $(this).fadeOut();
    });
    $("#" + loadingDivId).fadeOut();
    $("#" + id).removeAttr("disabled");
}

window.stopSpinner = function(spinner, id) {
    stopSpinnerByLoadingId(spinner, id, 'loading');
}

/****
 * VIEWPORT WIDE SPINNER ***
 */
window.viewportWideSpinner = {
    //A spinner that will be centered on the screen in a translucent background. Removes all other spinners around so forms will only use this one.

    init: function () {
        //Remove all instances of spinners inside any existing form. We want this one to be authoritative!
        $('form > #veSpinnerContainer, :not(#viewport-wide-spinner) > #veSpinnerContainer').remove();
        //Not initialised by Default, will be initiaised only once the component is added to the page.
    }
}