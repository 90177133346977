const debounce = require('lodash/debounce');
$(function () {
    if ($('.page-class-insights .jump-link-nav').length < 1) return;

    //used to populate the jumplink navigation
    window.populateJumpLinkNav = {
        currentBlockId: '',
        init: () => {
            populateJumpLinkNav.populateStickyNav();
            $(window).on('scroll', debounce(populateJumpLinkNav.showHideBanner,50));
        },
        //sticky nav for blog pages 
        populateStickyNav: () => {
            _html_jumplinkNav = '<div class="secondary-nav loaded sticky" id="sticky-waypoint"><nav class="container" id="secondary-nav"><ul class="secondary-nav__nav-list nav nav-fill">'
            $("body").attr("data-spy", "scroll");
            $("body").attr("data-target", "#secondary-nav");
            $("body").attr("data-offset", "20");
            $('.jump-link-nav').each(function () {
                _html_jumplinkNav += '<li class="secondary-nav__nav-list-item nav-item"><a href="#' + this.id.replace(/\./g, "") + '" class="secondary-nav__link nav-link">' + $(this).attr("data-jumplink-title") + '</a></li>';
            })
            _html_jumplinkNav += '</ul>';
            _html_jumplinkNav += '<div class="dropdown secondary-nav__nav-list-mobile no-print">'
                + '<button data-display="static" aria-haspopup="true" aria-expanded="false" class="dropdown__button text-util-sm dropdown__button-default" data-toggle="dropdown" type = "button" >'
                + '<span class="dropdown__label">Select Section</span>'
                + '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-16 fa-2x dropdown__icon dropdown__icon-default" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">'
                + '<path fill="currentColor" d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z">'
                + '</path>'
                + '</svg>'
                + '</button >'
                + '<div class="dropdown-menu dropdown__menu text-util-sm" role="menu">'
            $('.jump-link-nav').each(function () {
                _html_jumplinkNav += '<a href="#' + this.id.replace(/\./g, "") + '"class="dropdown-item dropdown__item text-util-sm">' + $(this).attr("data-jumplink-title") + '</a>';
            })
            _html_jumplinkNav += '</div></div>';
            _html_jumplinkNav += '</nav></div>';
            $(_html_jumplinkNav).insertAfter(".sticky-wrapper")
            $("#secondary-nav").hide();
        },
        showHideBanner: () => {
            if ($(window).scrollTop() >= $(".jump-link-nav").first().offset().top - 50) {
                $("#secondary-nav").show();
            }
            else {
                $("#secondary-nav").hide();
            }
        }
    }
    //initializing the functions
    populateJumpLinkNav.init()
})