/**
 *  Mini Video Player
 *  ==============
 *
 *  Implements the mini video player for the video pages.
 *
 *
 */

import { debounce } from "debounce";

$(function () {
    if ($(".video-js").length === 0) return; //no videos on this page, ignore script.

    function loadBrightcovePlayer(videoBlock, videoId) {

        const player = videojs.getAllPlayers('.video-js').find(v => v?.mediainfo?.id === videoId.toString());

        player.on('loadedmetadata', function () {
            const video = this;

            const videoWrap = videoBlock.parent().parent().parent();
            const videoPlayer = videoBlock.parent().parent();
            const videoHeight = videoPlayer.outerHeight();

            function stickyVideoPlayer() {
                const isPlaying = !!(
                    video.currentTime() > 0 &&
                    !video.paused() &&
                    !video.ended() &&
                    video.readyState() > 2
                );
                const isInPictureInPicture = video.isInPictureInPicture_;
                const windowScrollTop = $(window).scrollTop();
                const windowScrollBottom = $(window).scrollTop() + $(window).height();

                const navigationBarOffset = 150;
                const videoBottom = videoHeight + videoWrap.offset().top - navigationBarOffset;

                /**
                 * Add sticky class if below are true
                 * - video is playing
                 * - video is not playing inside 'picture in picture' mode
                 * - scroll direction top to bottom and video is scrolled up
                 * - scroll direction bottom to top and video is scrolled bottom
                 */
                if (
                    isPlaying &&
                    !isInPictureInPicture &&
                    ((windowScrollTop > videoBottom) || (windowScrollBottom < videoWrap.offset().top))
                ) {
                    videoWrap.height(videoHeight);
                    videoPlayer.addClass('sticky');
                }
                else {
                    videoWrap.height('auto');
                    videoPlayer.removeClass('sticky');
                }
            }

            $('.embed-block__close-player')?.on('click', function () {
                video.pause();
                videoPlayer.removeClass('sticky');
            });

            $('.vjs-picture-in-picture-control')?.on('click', function () {
                videoPlayer.removeClass('sticky');
            });

            $(window).on('scroll', debounce(stickyVideoPlayer, 50));
        });
    }

    $('.video-js')?.each(function () {

        //If below false, This means that brightcove has already been initialised for this video.
        //Use the child video tag instead.
        const videoBlock = $(this).find('video').length === 0 ? $(this) : $($(this).find('video')[0]);


        const playerAccountId = videoBlock.data('account');
        const videoId = videoBlock.data('video-id');

        // check if we have already added script with same account!
        if ($(`#brightcove-player-${playerAccountId}`).length == 0) {
            const bcScript = document.createElement('script');
            bcScript.setAttribute(
                'src', `https://players.brightcove.net/${playerAccountId}/default_default/index.min.js`,
            );
            document.body.appendChild(bcScript);
            bcScript.id = `brightcove-player-${playerAccountId}`;
            bcScript.onload = function () {
                loadBrightcovePlayer(videoBlock, videoId);
            };
        }
        else {
            loadBrightcovePlayer(videoBlock, videoId);
        }
    });
});