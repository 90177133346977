function validateETH() {
    //guard run only if ETH field is there...
    if($("input.ETH.Address").length === 0) return;
    
    $("input.ETH.Address").change(function () {
        const val = this.value;
        if (isETHAddress(val)) {
            $(this).parents('form.js-aspnet-form').find("button.FormSubmitButton").prop("disabled", false);;
            return $(this).parent().find(".Form__Element__ValidationError").text("").hide()
        } else {
            $(this).parents('form.js-aspnet-form').find("button.FormSubmitButton").prop("disabled", true);;
            return $(this).parent().find(".Form__Element__ValidationError").text("Please enter a valid ETH address.").show()
        }
    });


    /**
     * Checks if the given string is an address
     *
     * @method isAddress
     * @param {String} address the given HEX adress
     * @return {Boolean}
    */
    var isETHAddress = function (address) {
        if (typeof address != "string") return false;
        address = address.toLowerCase();
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
            // check if it has the basic requirements of an address
            return false;
        } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
            // If it's all small caps or all all caps, return true
            return true;
        } else {
            // Otherwise check each case
            return false;
        }
    };
}
window.addEventListener("DOMContentLoaded", validateETH);

function bindMarketoCookie()
{
    if ($("form input[value=marketo-cookie-id]").length === 0) return;

    //add Marketo cookie
    $("input[value=marketo-cookie-id]").val(GetMarketoId())
}
window.addEventListener("DOMContentLoaded", bindMarketoCookie);