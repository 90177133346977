$(document).ready(function () {
    var personalizedElement = $('div[data-personalized-content="personalizedContent"]');
    if (personalizedElement.length > 0) {
        $.each($('div[data-personalized-content="personalizedContent"]'), function (index, value) {
            var parentContainer = $(this).parent();
            var getContentUrl = parentContainer.children('input.js-hdnPersonalizedContentUrl').val();
            var checkContentUrl = parentContainer.children('input.js-hdnIsPersonalizedContentUrl').val();
            var bodyClass = parentContainer.children('input.js-hdnPersonalizedBodyClass').val();
            var hdnPersonalizedPageUrl = parentContainer.children('input.js-hdnPersonalizedPageUrl').val();
            $.each($(this).children("div.js-load-async"), function (index, value) {
                var contentId = $(this).data("personalized-block");
                $.ajax({
                    type: 'GET',
                    url: '' + getContentUrl + '',
                    cache: true,
                    data: {
                        "contentId": contentId,
                        "css": bodyClass,
                        "parenturl": hdnPersonalizedPageUrl
                    },
                    async: true,
                    success: function (msg) {
                        $(value).html(msg);
                        
                        // detectwidth is declared in content and adding narrow-width-form class to the narrow blocks
                        if (typeof detectwidth !== "undefined") {
                            detectwidth.init('.manage-subscriptions-block, .new-subscription-block, .one-click-form, .manage-subscriptions-link');
                        }
                    },
                    error: function (result) {
                    }
                });
            });

            $.each($(this).find("div.js-loaded-sync"), function (index, value) {
                var contentId = $(this).data("personalized-block");
                $.ajax({
                    type: 'GET',
                    url: '' + checkContentUrl + '',
                    cache: true,
                    data: {
                        "contentId": contentId,
                        "css": bodyClass,
                        "parenturl": hdnPersonalizedPageUrl
                    },
                    async: true,
                    success: function (msg) {
                        if (msg === "True") {
                            $(value).show();
                        }
                    },
                    error: function (result) {
                    }
                });
            });
        });
    }
});