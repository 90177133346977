export function getHeaderHeight() {
    const headers = document.querySelectorAll(".root header.site-header");
    const headerNativeHeight = [...headers].reduce((prev, headerElement) => {
        return prev + Math.round(
            headerElement?.getBoundingClientRect().height ?? 0
        );
    }, 0);

    return headerNativeHeight;
}
