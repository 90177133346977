const debounce = require('lodash/debounce');
const { getHeaderHeight } = require("./getHeaderHeight");

function AccordionScripts() {

    /*** ACCORDIANS 
 * preventDefault is used since the page is encapsulated by a form tag and the button triggers a form send... ****/
    $('button.accordion__toggle').click(function (e) {
        e.preventDefault();
    })

    //trigger tooltips
    $(".trigger-tooltip").tooltip()

}

window.addEventListener("DOMContentLoaded", AccordionScripts);
//to add the sticky nav height to the header if alert bars are not available

/* Tab component */
window.addEventListener("load", function () {

    $('.content-area-tab .tablink').on('show.bs.tab', function (e) {
        $(e.currentTarget).parent().find(".active").removeClass("active"); // previous active tab
    })

    $('.content-area-tab').each(function (i, e) {
        $(e).find('.tablink:first').click();
    })
})